<script>
	import '../node_modules/purecss/build/pure-min.css'
	import Login from './Login.svelte'
	import Domains from './Domains.svelte'
	import Accounts from './Accounts.svelte'
	import Aliases from './Aliases.svelte'
	import Settings from './Settings.svelte'
	import { token } from './Config.svelte'
	import { writable } from 'svelte-persistent-store/dist/local'

	// Create localstorage variables
	const curpage = writable('page', 'domains')
	let messages = []
	let warnings = []
	let errors = []
	let pages = ['domains', 'accounts', 'aliases', 'settings']

	function setToken(e) {
		token.set(e.detail)
		if (pages.includes($curpage)) {
			setPage($curpage)
		} else {
			setPage('settings')
		}
	}

	function addMessage(e) {
		console.log('Received message ' + e.detail)
		messages.push(e.detail)
		messages = messages
	}

	function addWarning(e) {
		console.log('Received warning ' + e.detail)
		warnings.push(e.detail)
		warnings = warnings
	}

	function addError(e) {
		console.log('Received error ' + e.detail)
		errors.push(e.detail)
		errors = errors
	}

	function logout() {
		token.set('')
	}

	function setPage(page) {
		curpage.set(page)
	}

	function clearNotifications() {
		messages = []
		warnings = []
		errors = []
	}

	function handleKeys(e) {
		if (e.key == 'Tab') {
			e.preventDefault()
			let max = pages.length - 1
			let p = pages.indexOf($curpage)
			if (p == max) {
				p = 0
			} else {
				p++
			}
			setPage(pages[p])
		}
	}
</script>

<style>
	menu {
		background-color: antiquewhite;
		color: black;
		margin: 0;
		padding: 0;
	}

	.notification {
		border: solid 2px;
		border-radius: 3px;
	}

	.message {
		color: #004085;
		border-color: #cce5ff;
		background-color: #cce5ff;
	}

	.warning {
		color: #856404;
		border-color: #fff3cd;
		background-color: #fff3cd;
	}

	.error {
		color: #721c24;
		border-color: #f5c6cb;
		background-color: #f8d7da;
	}
</style>

<svelte:window on:keydown={handleKeys} />

<pagewrap>
	{#each warnings as warning}
		<div class="hcenter notification warning">{warning}</div>
	{/each}
	{#each messages as message}
		<div class="hcenter notification message">{message}</div>
	{/each}
	{#each errors as error}
		<div class="hcenter notification error">{error}</div>
	{/each}
	{#if $token == ''}
		<div class="content-wrap">
			<Login on:token={setToken} on:error={addError} on:clear={clearNotifications} />
		</div>
	{:else}
		<menu class="pure-menu pure-menu-horizontal">
			<ul class="pure-menu-list maxwidth">
				<li class="pure-menu-item" class:pure-menu-selected={$curpage === 'domains'}>
					<label class="pure-menu-link" on:click={() => setPage('domains')}>Domains</label>
				</li>
				<li class="pure-menu-item" class:pure-menu-selected={$curpage === 'accounts'}>
					<label class="pure-menu-link" on:click={() => setPage('accounts')}>Accounts</label>
				</li>
				<li class="pure-menu-item" class:pure-menu-selected={$curpage === 'aliases'}>
					<label class="pure-menu-link" on:click={() => setPage('aliases')}>Aliases</label>
				</li>
				<li class="pure-menu-item" class:pure-menu-selected={$curpage === 'settings'}>
					<label class="pure-menu-link" on:click={() => setPage('settings')}>Settings</label>
				</li>
				<li class="pure-menu-item right">
					<label class="pure-menu-link" on:click={logout}>Log out</label>
				</li>
			</ul>
		</menu>

		<div class="content-wrap">
			{#if $curpage === 'domains'}
				<Domains />
			{:else if $curpage === 'accounts'}
				<Accounts />
			{:else if $curpage === 'aliases'}
				<Aliases />
			{:else if $curpage === 'settings'}
				<Settings on:error={addError} on:message={addMessage} on:clear={clearNotifications} />
			{/if}
		</div>
	{/if}
</pagewrap>
