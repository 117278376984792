<script>
	import Spinner from './Spinner.svelte'
	import { writable } from 'svelte-persistent-store/dist/local'
	import { config, token } from './Config.svelte'
	import { createEventDispatcher } from 'svelte'

	const dispatch = createEventDispatcher()
	let showChange
	let changing
	let req = {
		token: '',
		password: '',
		currentpassword: '',
		passwordagain: '',
	}

	async function changePassword(e) {
		dispatch('clear')
		changing = true
		req.token = $token
		let res = await fetch(config.apiurl + '/password', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
			},
			body: JSON.stringify(req),
		})
		let msg = await res.json()
		changing = false
		if (msg.ok) {
			dispatch('message', 'Password changed.')
			token.set('')
			location.reload()
		} else {
			dispatch('error', msg.message)
		}
	}

	function toggleChange() {
		showChange = !showChange
	}
</script>

<form class="pure-form pure-form-aligned" on:submit|preventDefault={changePassword}>
	<button type="button" class="pure-button-primary" on:click={toggleChange}>Change password</button>
	{#if showChange}
		{#if changing}
			<Spinner />
		{:else}
			<fieldset>
				<div class="pure-control-group">
					<input
						type="password"
						placeholder="current password"
						name="oldpass"
						required
						bind:value={req.currentpassword} />
				</div>
				<div class="pure-control-group">
					<input
						type="password"
						placeholder="new password"
						name="password"
						required
						bind:value={req.password} />
				</div>
				<div class="pure-control-group">
					<input
						type="password"
						placeholder="new password again"
						name="password2"
						required
						bind:value={req.passwordagain} />
				</div>
				<div class="pure-control-group">
					<button type="submit" class="pure-button pure-button-primary">OK</button>
				</div>
			</fieldset>
		{/if}
	{/if}
</form>
