<script>
	import Spinner from './Spinner.svelte'
	import { config } from './Config.svelte'
	import { createEventDispatcher } from 'svelte'

	const dispatch = createEventDispatcher()
	let connecting = false
	let remember
	let user = {
		username: '',
		password: '',
	}

	async function authUser() {
		dispatch('clear')
		connecting = true
		let res = await fetch(config.apiurl + '/auth', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
			},
			body: JSON.stringify(user),
		})
		let msg = await res.json()
		if (msg.ok) {
			dispatch('token', msg.message)
		} else {
			dispatch('error', msg.message)
		}
		connecting = false
	}
</script>

<style>
	loginspinner {
		margin: 50px;
	}

	form {
		margin: auto;
		width: 20%;
	}
</style>

{#if !connecting}
	<form class="pure-form pure-form-aligned vhcenter" on:submit|preventDefault={authUser}>
		<fieldset>
			<div class="pure-control-group">
				<legend>Log in if you have an account</legend>
			</div>
			<div class="pure-control-group">
				<input type="email" placeholder="e-mail" name="email" required bind:value={user.username} />
			</div>
			<div class="pure-control-group">
				<input type="password" placeholder="password" name="password" required bind:value={user.password} />
			</div>
			<div class="pure-control-group">
				<label for="default-remember">
					<input type="checkbox" id="default-remember" bind:value={remember} />
					Remember me
				</label>
			</div>
			<div class="pure-control-group hcenter">
				<button type="submit" class="pure-button pure-button-primary">Sign in</button>
			</div>
		</fieldset>
	</form>
{:else}
	<loginspinner>
		<Spinner />
	</loginspinner>
{/if}
